<template>
  <div>

    <validation-observer
      tag="div"
      v-for="(item, itemIndex) in vItems"
      :ref="`${slotConfig.name}_${itemIndex}`"
      :vid="`${slotConfig.name}_${itemIndex}`"
      :key="item.id"
      class="mb-05"
    >
      <b-row>
        <b-col cols="12" class="d-flex">
          <div class="flex-grow-1 d-flex">
            <b-input-group :id="`${slotConfig.name}_${itemIndex}_itemText`">
              <b-form-input
                type="text"
                :value="vItemsText[itemIndex]"
                :class="slotConfig.input_class"
                style="height:100%;background-color:transparent;"
                v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                readonly
              />
              <b-input-group-append>
                <b-button
                  v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                  variant="primary"
                  style="height:100%;"
                >編輯
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button
            v-if="slotConfig.show_del_btn"
            class="flex-shrink-0 ml-1"
            variant="secondary"
            @click="deleteItem(itemIndex)"
            :disabled="vItems.length <= slotConfig.min_count"
          >
            {{ $t('common.delete') }}
          </b-button>
        </b-col>

        <!-- 檢測當前 item 是否符合規則 -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          :ref="`${slotConfig.name}_${itemIndex}_main`"
          :vid="`${slotConfig.name}_${itemIndex}_main`"
          :name="$t(slotConfig.label)"
          :customMessages="slotConfig.custom_messages"
          :rules="slotConfig.rule"
        >
          <b-col
            cols="12"
            v-show="errors.length !== 0 && (!slotConfig.check_duplicate || !duplicateItems.includes(vItemsText[itemIndex]))"
          >
            <input type="hidden" :value="item.value.qrCode && item.value.organId > 0 ? item.value.qrCode : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!-- 檢測當前 item 與其他 items 之間是否存在重複 -->
        <validation-provider
          tag="div"
          :ref="`${slotConfig.name}_${itemIndex}_sub`"
          #default="{ errors }"
          :vid="`${slotConfig.name}_${itemIndex}_sub`"
          :name="$t(slotConfig.label)"
          :customMessages="{ length: slotConfig.duplicate_tips}"
          rules="length:0"
          v-if="slotConfig.check_duplicate"
        >
          <b-col
            cols="12"
            v-show="errors.length !== 0 && duplicateItems.includes(vItemsText[itemIndex])"
          >
            <input type="hidden" :value="duplicateItems.includes(vItemsText[itemIndex]) ? '_' : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

      </b-row>


      <b-modal
        :id="`${slotConfig.name}_${itemIndex}_modal`"
        title="編輯 QRCode"
        size="lg"
        :ok-title="$t('common.confirm')"
        centered no-fade no-close-on-backdrop ok-only static
      >
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_env`">機構<span
              style="color: red">*</span></label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <v-select
              :id="`${slotConfig.name}_${itemIndex}_env`"
              label="text"
              :options="organOptions"
              v-model="item.value.organId"
              :reduce="text => text.value"
              :clearable="false"
              :searchable="false"
              class="mb-1"
            >
              <template #no-options>{{ $t('common.no_options') }}</template>
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_file`">QRCode 原圖</label>
          </b-col>
          <b-col cols="12" md="8" lg="9" class="mb-1">
            <b-form-file
              :id="`${slotConfig.name}_${itemIndex}_file`"
              :ref="`${slotConfig.name}_${itemIndex}_file`"
              :disabled="(slotConfig.add_disable === true && !isEdit) || (slotConfig.edit_disable === true && isEdit)"
              v-model="vFiles[itemIndex]"
              :value="vFiles[itemIndex]"
              @input="onFileInput(itemIndex, arguments[0])"
              :accept="slotConfig.file_accept"
              :drop-placeholder="$t('batchupload.drop_placeholder')"
              :placeholder="slotConfig.placeholder"
              browse-text="選擇圖片"
            />
            <!-- preview source file -->
            <div class="mt-05">
              <div v-show="!vFilesBase64[itemIndex]" style="opacity: 0.5;">（選擇圖片以預覽）</div>
              <b-img
                v-show="!!vFilesBase64[itemIndex]"
                :src="vFilesBase64[itemIndex]"
                alt="QRCode 原圖"
                style="max-height:120px;"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_text`">QRCode 文本<span
              style="color: red">*</span></label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              type="text"
              :id="`${slotConfig.name}_${itemIndex}_text`"
              :value="item.value.qrCode"
              @change="onQrTextChange(itemIndex, arguments[0])"
              :class="slotConfig.input_class"
            />
            <!-- preview QRText -->
            <div class="mt-05">
              <div v-show="!vQrTextBase64[itemIndex]" style="opacity: 0.5;">（輸入文本以預覽）</div>
              <b-img
                v-show="!!vQrTextBase64[itemIndex]"
                :src="vQrTextBase64[itemIndex]"
                :alt="vItems[itemIndex].value.qrCode"
                style="max-height:120px;"
              />
            </div>
          </b-col>
        </b-row>
      </b-modal>

    </validation-observer>

    <b-button
      v-if="slotConfig.show_add_btn" variant="primary" @click="addItem()"
      :disabled="vItems.length >= slotConfig.max_count"
    >
      {{ $t('common.add') }}
    </b-button>

  </div>
</template>

<script>
import common from '@/common'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/utils/validations/validations'
import { getDuplicateItems } from '@/libs/ez-utils'
import jsQR from 'jsqr'
import QrCode from 'qrcode'

export default {
  name: 'FieldModalQrCodes',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    input_class: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    rootItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      autoIndex: 0,
      vItems: [], // 存放 QRCode 對象
      vItemDefaultValue: { // modelItem 的空值
        qrCodeId: 0,
        organId: 0,
        qrCode: '',
      },
      vQrTextBase64: [], // 存放 QRCode 文本的 base64 編碼

      vFiles: [], // 存放 QRCode 原圖的文件
      vFilesBase64: [], // 存放 QRCode 原圖的 base64 編碼

      qrGeneratorOptions: { margin: 0, width: 100 },

      organOptions: [],
      slotConfig: Object.assign({
        // type: 'text',
        name: 'defaultName',
        label: 'defaultLabel',
        min_count: this.rootItem.rule?.split('|')
          .includes('required') ? 1 : 0,
        max_count: 1,
        check_duplicate: true,
        duplicate_tips: this.$t('common.duplicate_content_tips'),
        show_del_btn: false,
        show_add_btn: false,
      }, this.rootItem.slot_config),
    }
  },

  computed: {
    vItemsText() {
      return this.vItems.map(item => {
        const organName = this.organOptions.find(organ => organ.value === item.value.organId)?.text || '(未選擇機構)'
        const qrText = item.value.qrCode || ''
        return qrText ? `${organName} | ${qrText}` : ''
      })
    },
    duplicateItems: {
      get() {
        return this.slotConfig.check_duplicate
          ? getDuplicateItems(this.vItemsText.filter(text => text !== ''))
          : []
      },
    },
  },

  watch: {
    items: {
      handler(items, _oldValues) {
        // 當 items 的值發生變化時，會先刪除多餘的 modelItems，再新增缺少的 modelItems
        for (const [i, item] of items.entries()) {
          while (this.vItems.length >= i + 1 && !_.isEqual(item, this.vItems[i].value)) {
            this.deleteItem(i, false)
          }
          if (this.vItems.length < i + 1) {
            this.addItem(_.clone(item))
          }
        }
      },
      deep: true,
    },
  },

  created() {
    // initiate 機構選項
    common.apiGetData('/memberexternal/GetExternalOrgans').then(res => {
      res.data?.organs.forEach(item => {
        this.organOptions.push({
          value: item.organID,
          text: item.organName,
        })
      })
    })
  },

  methods: {
    addItem(value, isUpdate = true) {
      this.vItems.push({
        value: value === undefined ? _.clone(this.vItemDefaultValue) : value,
        id: this.autoIndex,
      })
      if (value?.qrCode) {
        QrCode.toDataURL(value.qrCode, this.qrGeneratorOptions).then(base64 => {
          this.vQrTextBase64.push(base64)
        })
      } else {
        this.vQrTextBase64.push('')
      }
      this.vFiles.push(null)
      this.vFilesBase64.push('')
      this.autoIndex += 1
      isUpdate && this.updateValues()
    },
    deleteItem(itemIndex, isUpdate = true) {
      this.vItems.splice(itemIndex, 1)
      this.vQrTextBase64.splice(itemIndex, 1)
      this.vFiles.splice(itemIndex, 1)
      this.vFilesBase64.splice(itemIndex, 1)
      isUpdate && this.updateValues()
    },

    async onFileInput(itemIndex, file, isUpdate = true) {
      const fileSuffix = file?.name?.slice(file.name.lastIndexOf('.')) ?? ''
      const fileTypesAccept = this.slotConfig?.file_accept?.split(',') ?? []
      let qrText = this.vItemDefaultValue.qrCode

      // scan QRCode, and show alert while error occurs
      if (file && file.name) {
        if (fileTypesAccept.indexOf(fileSuffix) >= 0) {
          qrText = await this.genCode(itemIndex)
          if (!qrText) {
            common.showAlert({
              title: '掃描失敗，請確保圖片為一個正確的二維碼',
            })
          }
        } else {
          common.showAlert({
            title: this.slotConfig?.placeholder ?? '請選擇合適的文件類型',
          })
        }
      }

      // always update vItems & items
      this.$set(this.vItems[itemIndex].value, 'qrCode', qrText)
      isUpdate && this.updateValues()

      // reset vFiles & files if scan error
      if (!qrText) {
        file = null
        this.$set(this.vFiles, itemIndex, null)
        this.$refs[`${this.slotConfig.name}_${itemIndex}_file`][0].reset(null)
      }

      // update vFilesBase64
      if (qrText) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.$set(this.vFilesBase64, itemIndex, reader.result)
        }
        reader.onerror = error => {
          console.log('Error: ', error)
          this.$set(this.vFilesBase64, itemIndex, '')
        }
      } else {
        this.$set(this.vFilesBase64, itemIndex, '')
      }

      // update vQrTextBase64
      if (qrText) {
        QrCode.toDataURL(qrText, this.qrGeneratorOptions).then(base64 => {
          this.$set(this.vQrTextBase64, itemIndex, base64)
        })
      } else {
        this.$set(this.vQrTextBase64, itemIndex, '')
      }
    },

    onQrTextChange(itemIndex, value, isUpdate = true) {
      // update value
      this.vItems[itemIndex].value.qrCode = value
      isUpdate && this.updateValues()

      // update vQrTextBase64
      if (value) {
        QrCode.toDataURL(value, this.qrGeneratorOptions)
          .then(base64 => {
            this.$set(this.vQrTextBase64, itemIndex, base64)
          })
          .catch(err => {
            err?.message && common.showAlert({
              title: err.message,
            })
          })
      } else {
        this.$set(this.vQrTextBase64, itemIndex, '')
      }

      // clear file
      this.$refs[`${this.slotConfig.name}_${itemIndex}_file`][0].reset(null)
      this.vFiles[itemIndex] = null

      // check duplicate items
      if (this.slotConfig.check_duplicate) {
        this.vItems.forEach((v, i) => {
          this.$refs[`${this.slotConfig.name}_${i}_sub`][0].validate()
        })
      }
    },

    updateValues(itemIndex) {
      this.$emit('updateValues', this.vItems.map(item => item.value))
    },

    async genCode(itemIndex) {
      // console.log(this.modelItems[itemIndex].value)
      const imageFile = this.vFiles[itemIndex]
      let qrText = ''
      try {
        const { imageData, image } = await this.createImageDataFromFile(imageFile);
        const code = jsQR(imageData.data, image.width, image.height)
        qrText = code?.data ?? ''
      } catch (error) {
        console.error(error);
      }
      return qrText
    },
    createImageDataFromFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            const imageData = ctx.getImageData(0, 0, image.width, image.height);
            resolve({ imageData, image });
          };
          image.src = reader.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.field-row-label {
  padding: calc(0.438rem + 1px) 0;
}
</style>
